import ApiService from "./api-service";

const apiTokenKey = "SOULCIAL_API_TOKEN";
const localStorage =
  typeof window !== "undefined" ? window.localStorage : undefined;
const sessionStorage =
  typeof window !== "undefined" ? window.sessionStorage : undefined;

export default class TokenHandler {
  private static apiToken: string | null;
  private static tokenIsValid: boolean = false;

  constructor() {
    if (!TokenHandler.apiToken) {
      TokenHandler.apiToken = this.retrieveTokenFromStorage();
      if (!TokenHandler.tokenIsValid) {
        this.validateToken();
      }
    }
  }

  public setToken(token: string, rememberLogin: boolean = false): void {
    this.clearToken();
    TokenHandler.apiToken = token;
    TokenHandler.tokenIsValid = true;
    if (rememberLogin) {
      localStorage?.setItem(apiTokenKey, token);
    }
    sessionStorage?.setItem(apiTokenKey, token);
  }

  public get apiToken(): string | null {
    return TokenHandler.apiToken;
  }

  private retrieveTokenFromStorage(): string {
    let localToken = localStorage?.getItem(apiTokenKey);
    if (localToken) {
      return localToken;
    }

    return sessionStorage?.getItem(apiTokenKey) ?? "";
  }

  public clearToken(): void {
    localStorage?.removeItem(apiTokenKey);
    sessionStorage?.removeItem(apiTokenKey);
    TokenHandler.apiToken = null;
    TokenHandler.tokenIsValid = false;
  }

  // TODO - replace this with a more generic user profile endpoint
  public validateToken(): Promise<boolean> {
    if (!TokenHandler.apiToken) {
      return Promise.resolve(false);
    }

    let svc = new ApiService();
    return svc.get("/accounting/").then((result) => {
      if (result.statusCode === 200) {
        TokenHandler.tokenIsValid = true;
      } else {
        this.clearToken();
      }
      return TokenHandler.tokenIsValid;
    });
  }
}
