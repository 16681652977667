import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { UserDataContextProvider } from "../state/user-context";

function AppProviders({ children }: any) {
  return (
    <ThemeProvider theme={theme}>
      <UserDataContextProvider>{children}</UserDataContextProvider>
    </ThemeProvider>
  );
}
export default AppProviders;
