import React from "react";
import Button from "@mui/material/Button";
import { Link } from "gatsby";
import { useUserData } from "../state/user-context";
import ClientOnly from "./ClientOnly";

function UserLoginControls() {
  const { state } = useUserData();
  return (
    <div>
      <ClientOnly>
        {!state.isAuthenticated ? (
          <div>
            <Button component={Link} to="/login" color="inherit">
              Login
            </Button>
            <Button
              variant="outlined"
              component={Link}
              to="/signup"
              disabled
              sx={{ ml: ".5rem" }}
            >
              Register
            </Button>
          </div>
        ) : (
          <Button component={Link} to="/logout" color="inherit">
            Logout
          </Button>
        )}
      </ClientOnly>
    </div>
  );
}

export default UserLoginControls;
