import React, { createContext, useContext, useReducer, useEffect } from "react";
import ApiService from "../services/api-service";
import { userDataReducer } from "./user-reducer";

export type UserData = {
  email?: string;
  firstName?: string;
  lastName?: string;
  isAuthenticated: boolean;
};

// TODO - retrive from storage or api endpoint...
export const defaultUserState: UserData = {
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  isAuthenticated: false,
};

export type UserDataContextValue = {
  state: UserData;
  updateUserData: (data: Partial<UserData>) => void;
  clearUserData: () => void;
};

export const UserDataContext = createContext<UserDataContextValue>({
  state: { ...defaultUserState },
  updateUserData: (data: Partial<UserData>) => {},
  clearUserData: () => {},
});

export const UserDataContextProvider = ({ children }: any) => {
  const svc = new ApiService();
  const [state, dispatch] = useReducer(userDataReducer, {
    ...defaultUserState,
    isAuthenticated: svc.hasToken,
  });

  const updateUserData = (data: Partial<UserData>): void => {
    dispatch({
      type: "UPDATE_USER_DATA",
      payload: {
        ...state,
        ...data,
      },
    });
  };

  const clearUserData = (): void => {
    dispatch({
      type: "CLEAR_USER_DATA",
    });
  };

  useEffect(() => {
    if (svc.hasToken) {
      console.log("Has stored token, validating token...");
      svc.validateToken().then((isValid) => {
        if (isValid) {
          console.log("Token is valid");
          updateUserData({ isAuthenticated: true });
        } else {
          console.log("User token is no longer valid.");
          clearUserData();
        }
      });
    }
  }, []);

  return (
    <UserDataContext.Provider value={{ state, updateUserData, clearUserData }}>
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserData = (): UserDataContextValue => {
  const context = useContext<UserDataContextValue>(UserDataContext);
  return context;
};
