import React, { ReactElement } from "react";
import Fade, { FadeProps } from "@mui/material/Fade";
import NoSsr from "@mui/material/NoSsr";

type ClientOnlyProps = {
  transition?: "skeleton" | "fade" | "none";
  fadeProps?: Partial<FadeProps>;
  children: ReactElement<any, any> | ReactElement<any, any>[];
};

/**
 * Gatsby uses SSR, however some components require information that only the client has
 * (such as if a user is authenticated or not).
 * This will render it's child content only when the component is connected to a client,
 * thus preventing any rehydration issues upon load
 */
function ClientOnly({
  transition = "none",
  fadeProps,
  children,
}: ClientOnlyProps) {
  const content =
    transition === "none"
      ? children
      : React.Children.map(children, (child) => {
          return (
            <Fade in timeout={750} {...fadeProps}>
              {child}
            </Fade>
          );
        });

  return <NoSsr>{content}</NoSsr>;
}

export default ClientOnly;
