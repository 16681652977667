const API_LOCAL_BASE_URL = "http://127.0.0.1:8000";
const API_PROD_BASE_URL = "https://api.newsoulcialmedia.com";
export const API_BASE_URL =
  process.env.NODE_ENV === "development"
    ? API_LOCAL_BASE_URL
    : API_PROD_BASE_URL;

export type AuthLoginResponse = AuthTokenResponse | AuthErrorResponse;
export type AuthLogoutResponse = AuthErrorResponse | null;

export type AuthTokenResponse = {
  expiry: Date;
  token: string;
};

export type AuthErrorResponse = {
  detail: string;
};

export function isAuthErrorResponse(
  res: AuthLoginResponse | AuthLogoutResponse
): res is AuthErrorResponse {
  return (res as AuthErrorResponse).detail !== undefined;
}
