import React from "react";
import Layout from "./src/components/Layout";
import AppProviders from "./src/components/AppProviders";
import type { GatsbyBrowser } from "gatsby";
// import "@fontsource/nunito-sans";
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/900.css";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return <Layout>{element}</Layout>;
};

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  return <AppProviders>{element}</AppProviders>;
};
