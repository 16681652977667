import React from "react";
import { navigate } from "gatsby";
import Logo from "../images/nsm_logo.svg";
import SvgIcon from "@mui/material/SvgIcon";

type LogoHomeIconProps = {
  height?: string | number;
  width?: string | number;
};

const LogoHomeIcon: React.FC<LogoHomeIconProps> = ({ height, width }) => {
  return (
    // TODO -Remove the button here!!
    <SvgIcon
      component="object"
      fontSize="large"
      sx={{
        backgroundColor: "primary.main",
        borderRadius: "10%",
        height,
        width,
      }}
    >
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 2048.000000 2048.000000"
        preserveAspectRatio="xMidYMid meet"
        color="black"
        filter="invert(100%)"
      >
        <g
          transform="translate(0.000000,2048.000000) scale(0.100000,-0.100000)"
          fill="currentColor"
          stroke="#000000"
          strokeWidth="1px"
        >
          <path
            d="M2545 18526 c-261 -83 -589 -425 -788 -822 -66 -131 -134 -297 -232
-569 -48 -132 -119 -325 -160 -430 -40 -104 -78 -208 -85 -230 -7 -22 -19 -45
-27 -52 -11 -9 -4 -16 39 -36 78 -36 126 -83 209 -204 121 -177 214 -257 338
-289 104 -26 172 8 207 104 9 26 52 140 95 253 43 113 115 306 159 429 104
287 157 416 231 564 94 187 240 398 344 496 l37 35 -6 -355 c-17 -874 -62
-1442 -187 -2315 -68 -481 -106 -690 -149 -830 -241 -776 -479 -1796 -601
-2579 -58 -376 -109 -808 -109 -935 0 -79 2 -85 31 -119 51 -58 76 -126 99
-276 12 -76 29 -161 37 -190 71 -240 246 -349 355 -221 40 46 56 111 248 1025
67 316 184 856 261 1200 77 344 178 798 225 1010 88 403 111 481 229 800 362
971 832 1888 1257 2448 259 341 615 687 893 868 97 63 185 108 193 99 4 -4 7
-151 6 -328 0 -382 -12 -572 -74 -1239 -33 -356 -69 -792 -82 -1003 -17 -271
-17 -999 0 -1180 51 -545 131 -916 277 -1300 30 -77 72 -202 95 -278 80 -275
192 -518 334 -732 96 -143 261 -316 380 -395 156 -104 418 -214 623 -261 125
-29 347 -37 430 -16 172 45 260 130 323 313 33 94 21 177 -29 204 -23 12 -22
13 -50 -72 -13 -38 -28 -68 -34 -68 -6 0 -37 26 -68 59 -71 73 -116 164 -164
336 -41 147 -82 231 -133 276 l-35 31 -22 -69 c-29 -90 -46 -120 -92 -165 -57
-56 -132 -78 -263 -78 -180 0 -394 54 -602 152 l-107 51 -35 83 c-182 436
-267 1082 -238 1806 19 467 58 850 172 1713 127 958 155 1266 147 1595 -9 371
-69 579 -206 723 -102 107 -197 148 -340 148 l-96 0 -30 41 c-55 74 -189 193
-262 231 -138 73 -259 83 -403 36 -392 -127 -995 -674 -1375 -1247 -26 -40
-50 -70 -52 -68 -2 2 1 111 8 242 14 291 7 697 -16 842 -36 234 -120 374 -263
440 -47 21 -64 24 -136 20 -51 -3 -94 -11 -115 -22 l-34 -18 -94 100 c-177
191 -342 264 -488 218z m712 -432 c68 -31 94 -58 78 -84 -3 -5 -34 -9 -68 -8
-92 2 -164 -24 -275 -98 -114 -78 -150 -110 -246 -225 -87 -103 -163 -217
-245 -364 -77 -138 -80 -128 -5 14 121 228 206 342 417 558 l158 163 -2 62
c-2 46 -11 79 -32 123 l-30 60 89 -84 c66 -63 107 -93 161 -117z m2601 -473
c15 -19 57 -54 95 -78 79 -51 113 -102 85 -129 -22 -22 -54 -13 -105 31 -38
32 -48 36 -100 36 -132 0 -264 -56 -458 -195 -281 -200 -562 -477 -803 -788
-41 -54 -77 -98 -80 -98 -2 0 0 6 5 13 6 6 45 59 88 116 286 380 666 733 1010
936 121 72 143 96 156 174 9 56 8 67 -11 100 -17 30 -12 27 36 -24 31 -33 68
-75 82 -94z m-3442 -478 c-8 -15 -15 -25 -16 -21 0 12 23 58 27 54 3 -2 -2
-17 -11 -33z m-35 -80 c-12 -20 -14 -14 -5 12 4 9 9 14 11 11 3 -2 0 -13 -6
-23z m1226 -600 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m-32
-420 c-41 -509 -77 -788 -156 -1228 -68 -381 -129 -747 -129 -777 0 -67 -210
-839 -299 -1098 -107 -312 -301 -1049 -425 -1610 -135 -617 -166 -806 -173
-1065 -4 -175 -14 -245 -33 -245 -13 0 -30 85 -30 147 0 62 25 334 41 448 45
324 232 1182 359 1650 11 39 33 122 50 185 38 142 58 212 159 565 44 154 107
394 140 534 34 140 83 331 110 425 27 94 61 234 75 311 15 77 31 160 36 185
87 410 241 1390 282 1795 24 238 20 100 -7 -222z m895 317 c-12 -16 -24 -30
-26 -30 -3 0 4 14 16 30 12 17 24 30 26 30 3 0 -4 -13 -16 -30z m-2449 -257
c-12 -20 -14 -14 -5 12 4 9 9 14 11 11 3 -2 0 -13 -6 -23z m3749 -3063 c12
-74 39 -223 61 -330 94 -479 166 -675 321 -877 48 -62 49 -65 18 -43 l-33 24
6 -35 c10 -50 116 -260 185 -364 178 -268 382 -429 706 -556 169 -67 312 -100
462 -106 116 -5 140 -3 202 16 84 25 158 86 195 160 38 78 68 122 78 115 31
-19 -26 -149 -96 -219 -85 -86 -191 -121 -360 -119 -286 3 -685 137 -926 310
-154 111 -308 293 -432 509 -199 347 -383 999 -427 1514 -11 126 -6 198 10
156 4 -11 17 -81 30 -155z m-3260 -844 c-6 -44 -17 -115 -25 -156 -7 -41 -20
-115 -29 -165 -63 -361 -93 -527 -141 -785 -45 -240 -64 -356 -80 -485 -37
-302 -72 -530 -81 -529 -15 0 -71 90 -94 152 -13 31 -29 98 -38 148 l-16 90
28 100 c16 54 50 173 76 264 27 91 58 201 69 245 37 145 110 449 117 490 3 22
10 45 14 50 4 6 20 60 35 120 15 61 30 115 34 120 3 6 6 15 8 20 7 31 63 235
71 260 6 17 21 64 34 104 27 88 35 71 18 -43z m3860 -557 c0 -6 -4 -7 -10 -4
-5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z"
          />
          <path
            d="M10374 15641 c-313 -56 -611 -208 -826 -424 -447 -446 -685 -1188
-728 -2262 -22 -539 18 -1081 165 -2255 149 -1188 181 -1609 172 -2232 -5
-361 -16 -518 -52 -788 -72 -534 -225 -1020 -436 -1388 -214 -373 -475 -655
-819 -884 -545 -363 -1178 -485 -1769 -342 -295 72 -602 220 -856 414 -185
140 -423 380 -470 471 -38 73 -101 274 -127 399 -20 102 -23 143 -23 355 1
210 3 252 23 338 100 446 340 754 747 957 237 118 442 166 673 157 152 -5 238
-24 380 -82 223 -91 578 -321 828 -537 80 -69 93 -85 103 -125 18 -69 57 -140
109 -196 74 -79 148 -98 213 -54 83 56 102 146 53 246 -32 63 -165 205 -292
311 -62 51 -95 87 -106 113 -46 109 -148 232 -313 377 -339 298 -784 576
-1059 663 -261 83 -541 79 -819 -10 -402 -129 -717 -379 -880 -698 -96 -188
-134 -347 -142 -590 -7 -232 10 -367 77 -615 16 -58 36 -166 45 -239 87 -736
480 -1450 1070 -1948 224 -188 559 -383 805 -468 276 -95 505 -130 809 -122
382 9 686 86 1056 267 433 212 812 534 1094 930 489 686 731 1644 731 2890 0
458 -25 829 -125 1885 -113 1185 -131 1482 -122 2019 10 600 69 1050 198 1522
83 302 207 594 337 789 224 337 532 547 917 624 281 56 519 28 761 -89 114
-55 130 -72 223 -235 71 -124 128 -274 168 -445 19 -80 39 -154 43 -165 5 -11
13 -49 19 -84 6 -35 17 -71 26 -81 8 -9 15 -26 15 -37 0 -42 23 -132 32 -127
6 4 7 -2 3 -16 -4 -13 -3 -20 2 -17 10 6 68 -242 59 -251 -3 -3 -1 -11 4 -17
6 -7 13 -55 15 -105 4 -84 2 -96 -18 -122 -45 -58 -78 -89 -90 -83 -6 4 13
-17 43 -46 30 -29 84 -66 118 -82 58 -27 70 -29 132 -23 211 19 327 117 381
323 42 161 35 430 -17 645 -91 376 -338 742 -674 998 -190 144 -371 233 -603
295 -61 17 -120 42 -190 83 -185 108 -386 183 -581 217 -123 21 -380 19 -512
-4z m1287 -361 c57 -43 156 -124 219 -181 64 -57 152 -137 198 -178 91 -81
163 -168 211 -252 29 -52 41 -109 23 -109 -11 0 -59 52 -128 140 -266 340
-662 509 -1084 461 -270 -31 -528 -135 -722 -290 -190 -152 -322 -319 -455
-576 -40 -77 -73 -135 -73 -130 0 21 136 281 191 365 268 409 653 653 1138
721 110 15 283 6 394 -20 48 -12 92 -19 98 -15 14 8 -33 57 -98 100 -29 19
-53 39 -53 44 0 18 43 -5 141 -80z m-1820 -1157 c-12 -20 -14 -14 -5 12 4 9 9
14 11 11 3 -2 0 -13 -6 -23z m-20 -50 c-12 -20 -14 -14 -5 12 4 9 9 14 11 11
3 -2 0 -13 -6 -23z m-3641 -5823 c124 -11 207 -32 332 -85 170 -72 424 -227
668 -409 63 -47 118 -86 122 -86 13 0 20 87 8 110 -6 12 -9 24 -6 28 14 14 37
-28 56 -101 26 -105 64 -186 122 -266 52 -71 67 -106 50 -123 -20 -20 -54 5
-128 95 -240 293 -841 685 -1175 767 -119 29 -308 36 -429 16 -297 -48 -523
-154 -802 -375 l-43 -33 45 41 c230 211 565 370 860 410 47 6 96 13 110 15 49
7 96 6 210 -4z m-1906 -1199 c9 -84 24 -189 32 -232 31 -166 132 -422 232
-592 28 -48 49 -87 46 -87 -2 0 -34 46 -71 103 -36 56 -68 97 -70 91 -2 -5 8
-49 22 -97 83 -288 271 -644 487 -922 117 -151 350 -391 491 -506 282 -231
612 -404 925 -484 206 -52 271 -60 532 -60 276 1 400 17 624 81 65 19 121 33
123 31 5 -6 -232 -74 -332 -96 -325 -70 -699 -61 -1027 24 -685 178 -1314 697
-1698 1401 -193 355 -291 683 -345 1154 -16 140 -20 361 -8 374 13 12 20 -25
37 -183z m447 -1087 c17 -25 28 -48 25 -52 -8 -8 -17 3 -51 62 -33 59 -17 53
26 -10z m2969 -1574 c-8 -5 -19 -10 -25 -10 -5 0 -3 5 5 10 8 5 20 10 25 10 6
0 3 -5 -5 -10z"
          />
          <path
            d="M15775 9379 c-113 -28 -299 -149 -474 -309 -160 -146 -384 -409 -539
-634 l-75 -109 -38 82 c-85 184 -236 270 -420 238 l-47 -8 -56 81 c-96 136
-203 230 -313 273 -139 55 -290 15 -518 -137 -163 -109 -287 -216 -510 -440
-207 -207 -383 -410 -552 -635 -53 -72 -98 -129 -99 -128 -1 1 2 58 8 127 15
180 5 398 -21 481 -39 122 -105 190 -193 196 -47 4 -60 0 -113 -32 l-60 -37
-33 39 c-18 21 -69 88 -112 149 -84 118 -153 187 -228 225 -103 52 -183 17
-342 -148 -117 -121 -217 -268 -305 -448 -84 -172 -127 -293 -181 -503 -25
-97 -47 -179 -50 -184 -3 -4 -19 -1 -35 8 -47 24 -56 11 -14 -21 97 -77 129
-120 205 -271 63 -127 85 -160 147 -225 103 -107 208 -155 287 -129 72 23 113
90 153 248 43 167 20 -7 -108 -823 -78 -497 -128 -805 -140 -860 -6 -27 -40
-140 -76 -250 -268 -824 -518 -1953 -617 -2780 -9 -71 -26 -194 -38 -271 l-22
-141 22 -28 c49 -62 64 -129 72 -325 9 -209 25 -278 84 -366 78 -115 191 -134
253 -41 13 21 99 325 242 862 330 1242 391 1482 541 2122 63 268 86 340 200
638 362 943 803 1748 1334 2430 302 389 668 761 930 946 38 27 73 49 78 49 4
0 8 -82 8 -182 0 -261 -27 -553 -91 -973 -59 -390 -73 -451 -156 -669 -201
-533 -299 -866 -427 -1446 -74 -336 -114 -558 -123 -678 -6 -79 -5 -84 19
-110 60 -65 81 -124 103 -297 26 -200 62 -297 143 -387 46 -51 84 -70 139 -70
111 0 139 56 329 659 153 486 317 1069 432 1529 99 395 101 401 182 579 188
413 451 916 648 1240 286 471 593 853 871 1086 82 69 96 77 104 62 17 -29 0
-601 -32 -1083 -17 -250 -36 -567 -42 -703 -13 -282 -7 -1040 10 -1212 56
-570 121 -904 256 -1310 35 -104 84 -264 110 -355 172 -609 463 -1093 855
-1422 362 -304 946 -568 1465 -663 141 -26 391 -31 500 -11 140 27 242 80 340
180 86 87 168 218 180 287 7 43 -13 97 -44 116 -18 11 -23 6 -56 -55 -21 -37
-39 -69 -41 -71 -3 -3 -23 13 -45 35 -76 74 -128 191 -169 382 -11 53 -33 124
-49 157 -30 65 -76 125 -95 125 -7 0 -20 -17 -29 -39 -54 -122 -154 -230 -261
-281 -68 -32 -206 -60 -298 -60 -147 0 -358 35 -569 96 -379 108 -818 324
-1050 517 -62 52 -68 60 -121 183 -142 326 -232 693 -284 1154 -22 196 -30
843 -15 1118 6 114 23 331 37 482 24 268 36 386 108 1025 85 749 89 1138 15
1404 -48 174 -128 291 -241 352 -55 29 -72 34 -138 34 -41 0 -89 -4 -105 -8
-30 -7 -33 -5 -88 76 -76 113 -196 226 -280 266 -74 35 -164 47 -232 30z m568
-406 c32 -40 78 -85 104 -100 66 -41 93 -69 93 -97 0 -37 -37 -43 -91 -16 -34
18 -53 21 -92 17 -68 -8 -178 -60 -267 -126 -98 -71 -306 -276 -423 -414 -116
-138 -150 -176 -101 -112 168 221 369 431 550 576 167 134 173 142 170 226 -2
43 -11 86 -24 117 -28 61 -27 69 1 31 12 -16 48 -62 80 -102z m-2145 -380 c37
-43 83 -83 113 -100 57 -31 88 -75 68 -99 -15 -18 -37 -18 -74 2 -51 28 -137
21 -235 -17 -167 -66 -349 -208 -630 -489 -107 -107 -240 -249 -295 -314 -55
-66 -111 -131 -125 -146 -89 -93 171 218 296 355 206 225 461 455 638 575 39
26 92 66 117 89 45 40 47 42 51 108 4 51 0 79 -14 111 -24 57 -22 64 5 27 12
-16 50 -62 85 -102z m-2439 -247 c28 -21 72 -43 98 -49 40 -10 48 -16 51 -37
2 -21 -6 -29 -54 -54 -86 -45 -276 -237 -361 -366 -38 -58 -87 -138 -108 -178
-21 -40 -40 -71 -42 -68 -13 13 111 245 231 431 78 121 94 157 106 247 8 57 7
65 -24 127 -54 107 -53 122 2 50 28 -37 73 -83 101 -103z m3771 -280 c0 -2 -8
-10 -17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z m-30 -40 c0 -2 -8 -10
-17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z m-807 -346 c-7 -305 -25
-467 -104 -940 -32 -195 -59 -371 -59 -391 0 -80 -124 -454 -210 -634 -91
-191 -153 -341 -227 -549 -208 -586 -345 -1126 -359 -1411 -6 -125 -15 -165
-33 -165 -17 0 -33 99 -26 162 31 285 93 580 200 948 91 314 172 539 350 975
40 99 88 232 105 295 18 63 58 196 90 295 60 186 81 283 149 685 64 375 108
752 113 960 l1 75 7 -95 c3 -52 5 -147 3 -210z m-2638 -267 c-12 -122 -75
-553 -116 -798 -43 -257 -114 -621 -202 -1040 -72 -344 -116 -569 -121 -630
-10 -111 -201 -770 -345 -1190 -130 -376 -358 -1279 -426 -1680 -9 -55 -39
-199 -66 -320 -27 -121 -59 -263 -69 -315 -22 -104 -36 -140 -57 -140 -25 0
-14 114 32 317 24 109 60 272 79 363 181 857 304 1344 507 1999 38 123 101
346 139 495 38 149 84 321 103 381 43 136 60 212 197 860 61 286 126 590 145
675 82 374 160 796 191 1038 10 76 18 64 9 -15z m935 -26 c0 -2 -10 -12 -22
-23 l-23 -19 19 23 c18 21 26 27 26 19z m-40 -51 c0 -2 -8 -10 -17 -17 -16
-13 -17 -12 -4 4 13 16 21 21 21 13z m-30 -40 c0 -2 -8 -10 -17 -17 -16 -13
-17 -12 -4 4 13 16 21 21 21 13z m3424 -2266 c65 -450 137 -793 206 -980 62
-170 176 -378 292 -535 l30 -40 -33 35 c-17 19 -48 52 -66 73 -19 22 -37 37
-39 34 -8 -8 55 -157 111 -264 270 -512 643 -859 1200 -1115 314 -145 620
-232 920 -262 296 -29 466 12 611 147 39 37 77 80 84 96 7 17 20 33 31 36 10
3 30 20 44 37 21 25 28 29 36 17 14 -22 11 -32 -28 -97 -111 -182 -263 -281
-478 -312 -321 -45 -869 91 -1390 346 -282 138 -469 269 -670 469 -205 203
-336 386 -471 656 -186 370 -346 933 -415 1456 -23 179 -37 484 -22 468 6 -5
27 -125 47 -265z m-2738 -658 c-3 -10 -8 -39 -11 -66 -3 -26 -17 -105 -31
-175 -54 -258 -74 -376 -74 -425 0 -35 -2 -37 -17 -25 -26 22 -71 126 -89 205
l-16 72 44 116 c25 64 48 113 53 109 4 -5 4 3 0 17 -7 23 -7 24 10 10 17 -14
17 -13 10 9 -5 16 -4 21 4 17 6 -4 11 0 11 9 0 10 5 13 12 9 8 -5 9 -2 5 9 -3
10 -2 17 3 17 5 0 12 11 16 25 3 14 11 25 16 25 6 0 8 6 5 13 -5 14 17 39 27
30 3 -4 6 1 6 11 0 9 5 14 10 11 6 -4 9 -14 6 -23z m-2523 -117 c-3 -27 -6
-60 -7 -72 0 -13 -5 -23 -9 -23 -5 0 -6 -4 -3 -8 11 -18 -22 -204 -114 -642
-23 -107 -45 -215 -50 -240 -4 -25 -20 -97 -35 -160 -14 -63 -37 -164 -50
-225 -33 -154 -71 -324 -96 -430 -11 -49 -29 -128 -39 -175 -34 -157 -71 -324
-95 -430 -13 -58 -29 -127 -35 -155 -6 -27 -16 -70 -22 -94 -6 -24 -19 -89
-27 -144 -9 -55 -20 -102 -26 -104 -14 -4 -59 71 -80 131 -19 59 -34 167 -35
252 0 78 39 271 89 439 21 72 69 238 106 370 36 132 78 281 92 330 29 104 91
328 128 465 86 316 118 428 130 455 7 17 16 46 20 65 4 19 11 41 16 50 4 8 36
106 70 218 61 196 86 241 72 127z m5957 -953 c0 -20 -20 -2 -44 40 l-19 33 32
-29 c17 -17 31 -36 31 -44z"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default LogoHomeIcon;
