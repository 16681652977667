import { UserData } from "./user-context";

type UserDataActionType = "UPDATE_USER_DATA" | "CLEAR_USER_DATA";

type UserDataAction = {
  type: UserDataActionType;
  payload?: Partial<UserData>;
};

export const userDataReducer = (
  state: UserData,
  action: UserDataAction
): UserData => {
  const { type, payload } = action;
  switch (type) {
    case "UPDATE_USER_DATA": {
      return {
        ...state,
        ...payload,
      };
    }
    case "CLEAR_USER_DATA": {
      return {
        email: undefined,
        firstName: undefined,
        lastName: undefined,
        isAuthenticated: false,
      };
    }
  }
};
