import { createTheme } from "@mui/material/styles";
// https://materialui.co/colors
let theme = createTheme({
  palette: {
    background: {
      default: "#ffffff",
      paper: "#ffffff",
    },
    primary: {
      light: "#f05545",
      main: "#b71c1c",
      dark: "#7f0000",
      contrastText: "#ffffff",
    },
    // _alternatePrimary: {
    //   light: "#439889",
    //   main: "#00796b",
    //   dark: "#003d33",
    //   contrastText: "#ffffff",
    // },
    secondary: {
      light: "#ffffff",
      main: "#f6efe8",
      dark: "#c3bdb6",
      contrastText: "#000000",
    },
    text: {
      // primary: "#424242",
    },
    error: {
      main: "#B71C1C",
    },
  },
  typography: {
    // fontFamily: "Nunito Sans",
    fontFamily: "Montserrat",
    // fontWeightRegular: 500,
    // h1: {
    //   fontWeight: 700,
    // },
    // h2: {
    //   fontWeigth: 700,
    // },
    // h3: {
    //   fontWeight: 700,
    // },
  },
});

export default theme;
