import React, { useState } from "react";
import { navigate, Link } from "gatsby";
import useMediaQuery from "@mui/material/useMediaQuery";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import AppsIcon from "@mui/icons-material/Apps";
import CloseIcon from "@mui/icons-material/Close";
import CreateIcon from "@mui/icons-material/Create";
import HandshakeIcon from "@mui/icons-material/Handshake";
import HomeIcon from "@mui/icons-material/Home";
import ListItemIcon from "@mui/material/ListItemIcon";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";

import LogoHomeIcon from "./LogoHomeIcon";
import { useUserData } from "../state/user-context";
import UserLoginControls from "./UserLoginControls";

function TopNavBar() {
  const { state } = useUserData();
  const [openModal, setOpenModal] = useState(false);

  const authenticatedDrawerMenu = (
    <>
      <MenuItem onClick={() => navigateTo("/app")}>
        <ListItemIcon>
          <AppsIcon />
        </ListItemIcon>
        <ListItemText>All Apps</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => navigateTo("/app/accounting")}>
        <ListItemIcon>
          <CreateIcon />
        </ListItemIcon>
        <ListItemText>Accounting</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => navigateTo("/app/charity")}>
        <ListItemIcon>
          <HandshakeIcon />
        </ListItemIcon>
        <ListItemText>Daily Donation</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => navigateTo("/app/reminders")}>
        <ListItemIcon>
          <AccessAlarmIcon />
        </ListItemIcon>
        <ListItemText>Practice Reminders</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => navigateTo("/logout")}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText>Logout</ListItemText>
      </MenuItem>
    </>
  );

  const unauthenticatedDrawerMenu = (
    <>
      <MenuItem onClick={() => navigateTo("/login")}>
        <ListItemIcon>
          <LoginIcon />
        </ListItemIcon>
        <ListItemText>Login</ListItemText>
      </MenuItem>
    </>
  );

  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("sm")
  );

  const navigateTo = (path: string) => {
    setOpenModal(false);
    navigate(path);
  };

  return (
    <Box>
      <MuiAppBar
        position="static"
        elevation={0}
        className="top-nav-bar"
        color="transparent"
        sx={{
          pb: "1rem",
        }}
      >
        <Toolbar>
          <IconButton onClick={() => navigateTo("/")} sx={{ mr: ".5rem" }}>
            <LogoHomeIcon />
          </IconButton>
          <Typography
            noWrap
            variant="h6"
            component={Link}
            sx={{ textDecoration: "none", color: "black" }}
            to="/"
          >
            New Soulcial Media
          </Typography>
          <Box sx={{ flexGrow: 1 }}></Box>
          {!isSmallScreen && <UserLoginControls />}
          {isSmallScreen && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ ml: 2 }}
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          {/*TODO - seperate AppBarDrawer into own component*/}
          <Drawer
            anchor="right"
            open={openModal}
            onClose={() => setOpenModal(false)}
          >
            <Box sx={{ height: "100%", width: "100vw", pt: "1rem" }}>
              <Box display="flex">
                <IconButton
                  onClick={() => navigateTo("/")}
                  sx={{ mr: ".5rem" }}
                >
                  <LogoHomeIcon />
                </IconButton>
                <Typography
                  variant="h5"
                  textAlign="center"
                  display="inline"
                  alignSelf="center"
                >
                  New Soulcial Media
                </Typography>
                <Box textAlign="right" sx={{ flexGrow: 1, pr: "1rem" }}>
                  <IconButton onClick={() => setOpenModal(false)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
              <MenuList>
                <MenuItem onClick={() => navigateTo("/")}>
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText>Home</ListItemText>
                </MenuItem>
                {state.isAuthenticated
                  ? authenticatedDrawerMenu
                  : unauthenticatedDrawerMenu}
              </MenuList>
            </Box>
            {!state.isAuthenticated && (
              <Box sx={{ px: "1rem", mb: "1rem" }}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mb: ".5rem" }}
                  onClick={() => navigateTo("/login")}
                >
                  Login
                </Button>
                <Button fullWidth disabled variant="outlined">
                  Sign up
                </Button>
              </Box>
            )}
          </Drawer>
        </Toolbar>
      </MuiAppBar>
    </Box>
  );
}

export default TopNavBar;
