import {
  AuthLoginResponse,
  AuthLogoutResponse,
  isAuthErrorResponse,
  API_BASE_URL,
} from "./backend-config";
import TokenHandler from "./token-handler";
import { Buffer } from "buffer";

export type LoginCredentials = {
  username?: string;
  password?: string;
};

export type GetResult<T> = {
  statusCode: number;
  data: T;
};

export type CalloutResult<T> = {
  statusCode: number;
  data: T;
};

export type DeleteResponse = {
  statusCode: number;
};

export default class ApiService {
  private token: TokenHandler = new TokenHandler();

  public get hasToken(): boolean {
    return !!this.token.apiToken;
  }

  public validateToken(): Promise<boolean> {
    return this.token.validateToken();
  }

  public login(
    creds: LoginCredentials,
    rememberLogin = false
  ): Promise<number> {
    const { username, password } = creds;
    let statusCode: number;

    return fetch(API_BASE_URL + "/auth/login/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: this.encodeBasicAuthValue(username, password),
      },
    })
      .then((response) => {
        statusCode = response.status;
        return response.json();
      })
      .then((data: AuthLoginResponse) => {
        if (isAuthErrorResponse(data)) {
          throw data.detail;
        }

        this.token.setToken(data.token, rememberLogin);
        return statusCode;
      });
  }

  private encodeBasicAuthValue(username?: string, password?: string) {
    return `Basic ${Buffer.from(`${username}:${password}`).toString("base64")}`;
  }

  public logout(): Promise<number> {
    return this.handleLogout();
  }

  public logoutAll() {
    return this.handleLogout("logoutall/");
  }

  private handleLogout(logoutUri: string = "logout/") {
    let statusCode: number;

    return fetch(`${API_BASE_URL}/auth/${logoutUri}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${this.token.apiToken}`,
      },
    }).then((response) => {
      return response.status;
    });
  }

  public get<T>(path: string): Promise<GetResult<T>> {
    let statusCode: number;

    return fetch(API_BASE_URL + path, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${this.token.apiToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        statusCode = response.status;
        return response.json();
      })
      .then((data) => {
        return {
          statusCode,
          data,
        };
      });
  }

  public put<T = any>(path: string, payload?: any): Promise<CalloutResult<T>> {
    return this.doCallout<T>(path, "PUT", payload);
  }

  public post<T = any>(path: string, payload?: any): Promise<CalloutResult<T>> {
    return this.doCallout<T>(path, "POST", payload);
  }

  public patch<T = any>(
    path: string,
    payload?: any
  ): Promise<CalloutResult<T>> {
    return this.doCallout<T>(path, "PATCH", payload);
  }

  public delete(path: string): Promise<DeleteResponse> {
    return fetch(API_BASE_URL + path, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${this.token.apiToken}`,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      return {
        statusCode: response.status,
      };
    });
  }

  private doCallout<T>(
    path: string,
    method: string,
    payload?: any
  ): Promise<CalloutResult<T>> {
    let statusCode: number;

    return fetch(API_BASE_URL + path, {
      method: method,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${this.token.apiToken}`,
        "Content-Type": "application/json",
      },
      body: payload && JSON.stringify(payload),
    })
      .then((response) => {
        statusCode = response.status;
        return response.json();
      })
      .then((data: T) => {
        return {
          statusCode,
          data,
        };
      });
  }
}
