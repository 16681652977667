import React from "react";
import AppBar from "./AppBar";
import CssBaseline from "@mui/material/CssBaseline";

function Layout({ children }: any) {
  return (
    <div>
      <CssBaseline />
      <AppBar />
      {children}
    </div>
  );
}

export default Layout;
